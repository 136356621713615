import { createPortal } from 'react-dom';
import { FCWithChildren } from '../../types/FCWithChildren';

type RootPortalProps = {
  elementId?: string;
};

const RootPortal: FCWithChildren<RootPortalProps> = ({ elementId, children }) => {
  const mount = document.getElementById(elementId ?? 'portal-root');
  if (!mount) {
    return null;
  }
  return createPortal(children, mount);
};

export default RootPortal;
